body {
  background-image: url('/public/tiledBackground.png');
  background-repeat: repeat;
  font-family: 'Comic Sans MS', sans-serif;
  color: #000000;
  margin: 0;
  padding: 0;
}

header {
  background-color: #ffcc00;
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid #000;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  background-color: #0000ff;
}

nav ul li {
  margin: 0 10px;
}

nav ul li a {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
}

nav ul li a:hover {
  text-decoration: underline;
}

.home {
  text-align: center;
  padding: 20px;
}

.home h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.home p {
  font-size: 1.2em;
  margin-bottom: 40px;
}

.home section {
  margin-top: 40px;
}

.home ul {
  list-style-type: none;
  padding: 0;
}

.home ul li {
  display: inline;
  margin: 0 10px;
}

.home ul li a {
  text-decoration: none;
  color: #0000ff;
  font-weight: bold;
}

.home ul li a:hover {
  text-decoration: underline;
  color: #ff0000;
}

.footer {
  background-color: #ffcc00;
  padding: 10px;
  text-align: center;
  border-top: 2px solid #000;
  margin-top: 20px;
}

.visitor-counter {
  margin-top: 20px;
  font-size: 1.2em;
  text-align: center;
}

.marquee img {
  height: 50px; /* Adjust as necessary */
  vertical-align: middle;
  margin-right: 10px;
}

.screenshots {
  padding: 20px;
}

.screenshots-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
}

.screenshot-item {
  border: 1px solid #ffcc00;
  padding: 10px;
  border-radius: 8px;
  background: #ffcc00;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.screenshot-thumbnail-container {
  width: 100%;
  padding-top: 75%;
  position: relative;
  overflow: visible;
}

.screenshot-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease, z-index 0.3s ease;
  z-index: 1;
}

.screenshot-thumbnail-container:hover .screenshot-thumbnail {
  transform: scale(1.5);
  z-index: 10;
}

.screenshot-description {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.screenshot-description.expanded {
  -webkit-line-clamp: unset;
  max-height: none;
}

.read-more {
  color: blue;
  cursor: pointer;
  text-align: right;
  margin-top: 5px;
}

/* Media queries for different screen sizes */
@media (max-width: 1024px) {
  .screenshot-thumbnail-container:hover .screenshot-thumbnail {
    transform: scale(1.3); /* Adjust scale for medium screens */
  }
}

@media (max-width: 768px) {
  .screenshot-thumbnail-container:hover .screenshot-thumbnail {
    transform: scale(1.2); /* Adjust scale for smaller screens */
  }
}

@media (max-width: 480px) {
  .screenshot-thumbnail-container:hover .screenshot-thumbnail {
    transform: scale(1.1); /* Adjust scale for mobile screens */
  }
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.analytics {
  text-align: center;
  padding: 20px;
}

.chart-container {
  position: relative;
  width: 80%;
  height: 500px; /* Fixed height for the chart */
  margin: auto;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

p {
  font-size: 1.2em;
  margin-bottom: 40px;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  display: inline;
  margin: 0 10px;
}

ul li a {
  text-decoration: none;
  color: #ffcc00;
  font-weight: bold;
}

ul li a:hover {
  text-decoration: underline;
}

/* App.css */
.our-friends {
  font-size: 12px; /* Adjust the size as needed */
  color: black;
  text-align: center;
  padding: 20px; /* Add some padding */
  background-color: #f0f0f0; /* Light grey background for contrast */
  border: 1px solid #ccc; /* Light grey border */
  margin: 20px auto; /* Center the component and add space around it */
  width: 40%; /* Adjust the width as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add some shadow for depth */
}

.our-friends h2 {
  margin-bottom: 10px;
}

.our-friends ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0;
}

.our-friends li {
  margin: 5px 0;
}

.our-friends a {
  color: blue; /* Ensure the link color stands out */
  text-decoration: none; /* Optional: removes the underline */
  display: flex; /* Flexbox for alignment */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.our-friends a img {
  margin-right: 10px; /* Space between image and text */
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
}

.our-friends a:hover {
  text-decoration: underline; /* Optional: adds underline on hover */
}

/* Maze Card */
.profile-img {
  width: 40px; /* Adjust the width as needed */
  height: auto; /* This keeps the aspect ratio */
  margin-bottom: 10px; /* Adds some space below the image */
}

.social-icon {
  width: 20px; /* Example size for social media icons */
  height: 20px;
  margin: 0 5px; /* Optional: adds some space around icons */
}

.maze-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Ensure the cards are evenly spaced */
}


.maze-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 16px;
  width: 240px;
  background-color: #fff;
  transition: transform 0.2s;
}

.maze-card:hover {
  transform: translateY(-5px);
}

.maze-card img {
  max-width: 100%; /* Ensure the image fits the card width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
}

.maze-card h2 {
  font-size: 18px;
  margin: 10px 0;
}

.maze-card p {
  font-size: 14px;
  color: #555;
  margin: 5px 0;
}

.maze-card .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 10px;
}

.maze-card .tag {
  background-color: #e0e0e0;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  color: #333;
}

.maze-card .tag.difficulty-easy {
  background-color: #d4edda;
  color: #155724;
}

.maze-card .tag.difficulty-medium {
  background-color: #ffeeba;
  color: #856404;
}

.maze-card .tag.difficulty-hard {
  background-color: #f8d7da;
  color: #721c24;
}

.social-media-links {
  margin-top: 10px;
  display: flex;
  justify-content: center; /* Center the icons horizontally */
  gap: 10px;
}

.social-icon {
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
}

.marquee {
  width: 100%;
  background-color: #ffcc00;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-top: 20px;
}

.marquee img {
  vertical-align: middle;
  margin-right: 10px;
}

img.animated-gif {
  display: block;
  margin: 20px auto;
}

@media (max-width: 600px) {
  .home h1 {
    font-size: 2em;
  }

  .home p {
    font-size: 1em;
  }

  nav ul {
    flex-direction: column;
  }

  nav ul li {
    margin: 10px 0;
  }
}
